import {useLocation} from "react-router";

export enum Param {
	USER_HASH_ID = "userHashId",
	TAB_MENU = "tabMenu",
	INTERVENTION_MENU = "interventionMenu",
	INTERVENTION_TRACKER = "interventionTracker",
	FILE_ID = "fileId",
	CASELOAD_SUBPATH = "caseloadpath",
	GROUP_ID = "groupId",
	PROFILE_SUB_MENU = "profileTabMenu",
}

export enum ContactTabPath {
	DATA = "data",
	INSIGHTS = "insights",
	NETWORK = "network",
	PROFILE = "profile",
	ROM = "rom",
	FILES = "files",
	NOTES = "notes",
	INTERVENTION = "intervention",
}

export enum ProfileSubMenuPath {
	PROFILE = "profile",
	TREATMENT = "treatment",
	CONNECTIONS = "connections",
}

export enum InterventionMenuPath {
	NOTES = "notes",
	TRACKERS = "trackers",
	PSYCHOEDUCATION = "psychoeducation",
	BEHAVIOR_EXPERIMENT = "behavior-experiment",
	DIARIES = "diaries",
	SESSION = "session",
	TASK = "task",
	ASSESSMENT = "assessment",
	CUSTOM_EXERCISE = "custom-exercise",
}

export enum QueryString {
	Event = "event",
}

export enum CaseloadSubPath {
	INVITE_CLIENTS = "invite_clients",
	CONNECTED = "connected",
	PENDING = "pending",
	GROUPS = "groups",
}

export const Path = {
	APP: "/app",
	APP_THERAPIST_INFO: "/app/therapist/info",
	APP_THERAPIST_EDIT_BIO: "/app/therapist/edit/bio",
	APP_THERAPIST_EDIT_PERSONAL: "/app/therapist/edit/personal",
	APP_THERAPIST_EDIT_AVATAR: "/app/therapist/edit/avatar",
	APP_CLIENT_DETAIL: `/app/client/:${Param.USER_HASH_ID}`,
	APP_CLIENT_DETAIL_MENU: `/app/client/:${Param.USER_HASH_ID}/:${Param.TAB_MENU}`,
	APP_CLIENT_DETAIL_MENU_INTERVENTION: `/app/client/:${Param.USER_HASH_ID}/${ContactTabPath.INTERVENTION}/:${Param.INTERVENTION_MENU}`,
	APP_CLIENT_DETAIL_MENU_INTERVENTION_TRACKER: `/app/client/:${Param.USER_HASH_ID}/${ContactTabPath.INTERVENTION}/${InterventionMenuPath.TRACKERS}/:${Param.INTERVENTION_TRACKER}`,
	APP_CLIENT_DETAIL_SUB_MENU: `/app/client/:${Param.USER_HASH_ID}/profile/:${Param.PROFILE_SUB_MENU}`,
	APP_CLIENT: "/app/client",
	APP_THERAPIST_DETAIL: `/app/therapist/:${Param.USER_HASH_ID}`,
	APP_THERAPIST_DETAIL_MENU: `/app/therapist/:${Param.USER_HASH_ID}/:${Param.TAB_MENU}`,
	APP_THERAPIST: "/app/therapist",
	APP_GROUP_DETAIL: `/app/group/:${Param.GROUP_ID}`,
	APP_GROUP_MEMBERS: `/app/group-members/:${Param.GROUP_ID}`,
	APP_CONNECTION_REQUEST: "/app/connection-requests",
	APP_LIBRARY: "/app/library",
	APP_VIDEO_CALL: "/video.html",
	APP_INACTIVE_CLIENT: "/app/inactive-clients",
	APP_TIME_TRACKING: "/app/time-entries",
	APP_ACCOUNT_SETTINGS: "/app/account-settings",
	APP_TWO_FA: "/app/two-factor-authentication",
	APP_THERAPIST_EMAIL_VERIFICATION: "/app/email-verification",
	APP_CALENDARS: "/app/calendars",
	APP_THERAPIST_CASELOAD: "/app/caseload",
	APP_THERAPIST_CASELOAD_SUBPATH: `/app/caseload/:${Param.CASELOAD_SUBPATH}`,
	APP_TT_EXPORT: `/app/time-tracking/export/:${Param.FILE_ID}`,

	ORG: "/org",
	ORG__THERAPIST_MANAGEMENT: "/org/therapists",
	ORG__THERAPIST_MANAGEMENT__ACTIVE: "/org/therapists/active",
	ORG__THERAPIST_MANAGEMENT__PENDING: "/org/therapists/pending",
	ORG__THERAPIST_MANAGEMENT__DEACTIVATED: "/org/therapists/deactivated",
};

export const isPortalInAppMode = (pathname: string): boolean => {
	return pathname.startsWith(Path.APP);
};

export const isPortalInOrgMode = (pathname: string): boolean => {
	return pathname.startsWith(Path.ORG);
};

export const useIsPortalInOrgMode = (): boolean => {
	const location = useLocation();
	return location.pathname.startsWith(Path.ORG);
};

/**
 * Helper to check whether the given two paths are equal.
 * @param path Must use the `Path` dictionary from `Path.ts` file.
 * @param comparedPath A string to be compared with one of the path in `Path` above.
 */
export function isPathEquals(path: string, comparedPath: string) {
	const firstToken = path.split("/");
	const secondToken = comparedPath.split("/");

	if (firstToken.length !== secondToken.length) return false;
	return firstToken.every((token, idx) => token[0] === ":" || token === secondToken[idx]);
}
